import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import IconButton from '../components/button.jsx';

import logo from '../img/logo.svg';
import arrowReturn from '../img/arrow-return.svg';
import pdfLinkedIn from '../img/flyer-linkedin.pdf';


const OffersPage = () => {

useEffect(() => {
    const offersDiv = document.querySelector('.offers');
    
    if (offersDiv) {
      document.body.classList.add('offers-active');
    }

    return () => {
      document.body.classList.remove('offers-active');
    };
  }, []); 

  return (
    <div className='flex items-center flex-col offers container'>
      <Link to="/" className='return'>
        <img src={arrowReturn} alt='flèche blanche vers la gauche dans un rond noir'/>
        <h2 className='h3-footer text-chocolate'>Retour</h2>
      </Link>
      <div className='logo'>
        <img src={logo} alt='logo Expert SA' />
      </div>
      <h1 className='text-chocolate-60 h2'>Découvrez nos <span className='text-sunset'>offres</span> du<span className='text-chocolate'> moment</span>&nbsp;!</h1>
      <h2 className='text-chocolate-60 h2'><span className='text-sunset'>Spécialement</span> pour les<span className='text-chocolate'> université d'été</span>&nbsp;!</h2>
      <div className='grid-btn'>
        <div className='gap-10'>
          <div className='card-border'>
            <h2 className='h3 text-chocolate'><span className='text-sunset'>-20%</span> sur les offres LinkedIn</h2>
            <p className='text-chocolate-60'>Bénéficiez d’une réduction de 20 % sur les offres LinkedIn les 6 premiers mois, pour les 10 premiers clients</p>
          </div>
          <div className='card-border'>
            <h2 className='h3 text-chocolate'><span className='text-sunset'>-15%</span> pour votre site web</h2>
            <p className='text-chocolate-60'>Bénéficiez d’une réduction de 15 % sur la création de votre pack au choix (Mousse ou Ébullition) jusqu’au 29 septembre.</p>
          </div>
          <div className='card-border'>
            <h2 className='h3 text-chocolate'><span className='text-sunset'>-10%</span> sur votre identité visuelle complète</h2>
            <p className='text-chocolate-60'>Bénéficiez d’une réduction de 10 % sur la création de votre pack au choix (Mousse ou Ébullition) jusqu’au 29 septembre.</p>
          </div>
        </div>
        <div className='gap-10'>
          <IconButton label="Nos offres CJEC" url={'https://www.expertsa.fr/#Cjec'} />
          <IconButton label="Nos offres Linkedin" url={pdfLinkedIn} />
        </div>
      </div>
    </div>
  );
};

export default OffersPage;