import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';

import logo from './img/logo.svg';
import linkedin from './img/linkedin.svg';
import instagram from './img/insta.svg';
import behance from './img/behance.svg';

import CalendlyButton from './components/CalendlyButton';
import IconButton from './components/button';

import OffersPage from './pages/offersPage.jsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div className='flex items-center flex-col homepage container'>
            <div className='logo'>
              <img src={logo} alt='logo Expert SA'/>
            </div>
            <h1 className='text-chocolate-60 h3'>L’agence <span className='text-chocolate'>digitale</span> et <span className='text-chocolate'>créative</span> des <span className='text-sunset'>experts-comptables</span></h1>
            
            
            
            <div className='grid-btn'>
              <div className='gap-10'>
                <IconButton label="Notre site internet !" url={'https://www.expertsa.fr/'} />
                <IconButton label="Nos prestations" url={'https://www.expertsa.fr/communication-experts-comptables'} />
                <Link to="/offers">
                  <IconButton label="Nos offres du moment" />
                </Link>
              </div>
              <div className='gap-10'>
                <IconButton label="Nos références" url={'https://www.behance.net/expertsa'} />
                <IconButton label="Je vous appelle !" url={'tel:+33175435390'} />
                <CalendlyButton />
              </div>
            </div>




            <div className='icon-social-network'>
              <a href='https://www.linkedin.com/company/expert-sa/' target='_blank' rel='noopener noreferrer'>
                <img src={linkedin} alt='logo LinkedIn'/>
              </a>
              <a href='https://www.instagram.com/expert_sa/?hl=fr' target='_blank' rel='noopener noreferrer'>
                <img src={instagram} alt='logo Instagram'/>
              </a>
              <a href='https://www.behance.net/expertsa' target='_blank' rel='noopener noreferrer'>
                <img src={behance} alt='logo Behance'/>
              </a>
            </div>
          </div>
        } />
        <Route path="/offers" element={<OffersPage />} />
      </Routes>
    </Router>
  );
}

export default App;
