// src/components/CalendlyButton.jsx
import React, { useEffect } from 'react';

const CalendlyButton = () => {
  const handleClick = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: 'https://calendly.com/expertsa-ssam/mes-projets',
      });
    }
  };

  return (
    <button onClick={handleClick} className='btnesa'>
        <p className='h3-footer text-chocolate'>Je prends rendez-vous</p>
    </button>
  );
};

export default CalendlyButton;
