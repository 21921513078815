import React from 'react';

const IconButton = ({ label, url }) => {
  return (
    <a href={url} 
    target='_blank' 
    rel='noopener noreferrer' 
    className=''>
      <div className='btnesa'>
        <h2 className='h3-footer text-chocolate'>{label}</h2>
      </div>
    </a>
  );
};

export default IconButton;